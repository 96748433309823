import React from 'react'
import { Link } from 'gatsby'
import LayoutGlobal from '../components/LayoutGlobal'
import Seo from '../components/Seo'
import { FaRegEnvelope, FaPhoneAlt, FaWhatsapp, FaSkype, FaFacebookMessenger } from "react-icons/fa"

export default function Kontakt() {
  return (
    <LayoutGlobal>

      <Seo 
        title="Kontakt"
        description="Chcesz się ze mną skontaktować? Umówić wizytę lub zaproponować współpracę? Napisz do mnie lub dzwoń bezpośrednio."
        url="https://www.fizjopersonalny.pl/kontakt"
      />

      <div className='contact_box'>

        <h1>Kontakt</h1>

        <section>
          <div className='contact_content'>
            <p>Od lat pomagam osobom z bólem poprawić komfort życia. Pokażę Ci jak zlikwidować ból, odzyskać optymalną sprawność i poprawić wygląd sylwetki.</p>
            <p><b>Zarezerwuj wizytę</b> za pośrednictwem wiadomości e-mail lub bezpośrednio w rozmowie telefonicznej.</p>

            <ul>
              <a href="mailto:lukasz@fizjopersonalny.pl" target="_blank" rel="noreferrer">
                <li>
                  <FaRegEnvelope color="#fff" size="1rem"/>Napisz do mnie
                </li>
              </a>
              <a href="tel:0048790898938" target="_blank" rel="noreferrer">
                <li>
                  <FaPhoneAlt color="fff" size="1rem"/>Zadzwoń teraz 
                </li>
              </a>
            </ul>

          </div>
        </section>

        <section>
          <div className='contact_icons'>
            <p>Możesz również skorzystać z alternatywnych kanałów komunikacyjnych, przedstawionych poniżej:</p>

            <ul>
              <a href="https://api.whatsapp.com/send?phone=48790898938" target="_blank" rel="noreferrer">
                <li><FaWhatsapp size="2em"/>whatsapp</li>
              </a>
              <a href="https://join.skype.com/invite/gGCi9g68AdO3" target="_blank" rel="noreferrer">
                <li><FaSkype size="2em"/>skype</li>
              </a>
              <a href="https://m.me/FizjoPersonalny" target="_blank" rel="noreferrer">
                <li><FaFacebookMessenger size="2em"/>messenger</li>
              </a>
            </ul>

          </div>
        </section>

        <section>
          <div className='contact_visit'>
            <p>Wizyta zarezerwowana? Sprawdź jak się do niej dobrze przygotować.</p>
            <Link to="/wizyta" className='button contact_button'>Pierwsza wizyta</Link>
          </div>
        </section>

      </div>

    </LayoutGlobal>
  )
}